import { useTranslation } from "react-i18next";
import "../styles.css";

export default function MobileMessage({ image, head, answer }) {
  const { t } = useTranslation();

  return (
    <div className="mobile-tests">
      <div className="main-view">
        <div className="main-view-inset">
          <div className="connectionContent" style={{ position: "inherit" }}>
            {image && (
              <div>
                <img src={image} alt="" style={{ width: "70%" }} />
              </div>
            )}

            {head && (
              <div className="">
                <h2 style={{ fontSize: "1.8rem", fontWeight: "normal", color: "#216e75" }}>
                  {head}
                </h2>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="main-nav">
        <div className="button-default" onClick={() => answer({})}>
          {t("Next")}
        </div>
      </div>
    </div>
  );
}
