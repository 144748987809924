import "../styles.css";
import { useTranslation } from "react-i18next";
import TestWaitScreen from "../TestWaitScreen";

export default function DesktopFollowInstructions({ answer }) {
  const { t } = useTranslation();

  return (
    <TestWaitScreen
      head={t("Follow the instructions on your smartphone")}
    />
  );
}
