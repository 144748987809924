import { storageTest } from "../../hooks/useNewTest";
import {
  FunctionAddPower,
  FunctionEyeValence,
  FunctionMeasuredRx,
  FunctionWeightedRx,
  SPEMethods,
} from "../../intake/AJmath";
import { INITVA, Q3_ORIENTATIONS, VA_SIZES } from "../../utils/constants";
import { getAngle, getRandomInt } from "../../utils/core";

export const calculateAxisRatio = () => {
   // Comment: Removed placecard page and added ratio calculation here.
   var query =
   "(-webkit-min-device-pixel-ratio: 2), (min-device-pixel-ratio: 2), (min-resolution: 192dpi)";
   // let scR = (size.x / objects[object].width).toFixed(5);
   const cardRatio = 8.56 / 5.398;
   let ratio = (
     cardRatio *
     (cardRatio * (window.screen.width / 8.56)) *
     0.1
   ).toFixed(5);

   if (!matchMedia(query).matches) {
     ratio = ratio / 2;
   }

    return ratio;
}

export const calculateCRatio = () => {
  var query =
    "(-webkit-min-device-pixel-ratio: 2), (min-device-pixel-ratio: 2), (min-resolution: 192dpi)";
  const screenWidth = window.screen.width;
  const screenHeight = window.screen.height;
  const isRetina = matchMedia(query).matches;

  // Assume monitor padrão de 24 polegadas diagonal
  const standardMonitorDiagonal = 24;

  // Calcula diagonal em pixels
  const diagonalResolution = Math.sqrt(
    Math.pow(screenWidth, 2) + Math.pow(screenHeight, 2)
  );

  // Calcula PPI real
  let actualPPI = diagonalResolution / standardMonitorDiagonal;
  if (isRetina) {
    actualPPI *= 2;
  } 
  // else {
  //   actualPPI *= 2;
  // }

  // Tamanho base do C para VA 10% em 96 PPI a 3 metros
  const baseCSize = INITVA;

  // Ajusta o tamanho do C considerando PPI real
  const scaleFactor = actualPPI / 96;
  const adjustedCSize = baseCSize * scaleFactor;
  return adjustedCSize;
};

export function getNewVA(currentVaIdx) {
  // MAX or MIN, remove same va
  return VA_SIZES[currentVaIdx];
}

export const defaultAnswer = async (test, answer) => {
  test.steps[test.workflow].answers.push({ value: answer });
  test.steps[test.workflow].result = {
    finalizedAt: new Date(),
    value: answer,
  };

  await storageTest(test.token, test);

  return true;
};

export const defaultMoveNext = async (test, answer) => {
  test.steps[test.workflow].result = null;
  await storageTest(test.token, test);

  return true;
};

export const Custom4Circles = {
  load: (test) => {
    const ratio = calculateCRatio();
    test.steps[test.workflow] = {
      state: {
        ratio,
        or: getRandomInt(4),
        va: VA_SIZES[0],
        wrongCount: 0,
        currentVaIdx: 0,
        countMaxCurrentVaIdx: 0
      },
      answers: [],
    };
  },
};

export const Custom2Circles = {
  load: (test) => {
    const ratio = calculateCRatio();
    // TODO: why always based on PrimeDrPOVOD? could be changed for a random axis?
    const axis = test.steps?.testRectRight?.result?.value["PrimeDrPOVOD"] || 0;

    test.steps[test.workflow] = {
      state: {
        ratio,
        axis,
        or: getRandomInt(2),
        va: VA_SIZES[0],
        wrongCount: 0,
        currentVaIdx: 0,
        countMaxCurrentVaIdx: 0
      },
      answers: [],
    };
  },
};

export const CustomRectangle = {
  load: (test) => {
    const ratio = calculateAxisRatio();// test?.steps?.knowSpecs?.result?.value?.ratio;

    test.steps[test.workflow] = {
      state: {
        ratio,
        or: 0,
        wrongCount: 0,
        axis_x: 0,
        axis_y: 0,
      },
      answers: [],
    };
  },
};

export const processCirclesAnswer = async (test, answer, key, totalCircles) => {
  const step = test.steps[test.workflow];
  const state = step.state;

  //### based on core.js#handleQ2
  let { or, va, wrongCount, changeInt, currentVaIdx, countMaxCurrentVaIdx } = state;
  const originalVA = va;

  const answerCorrect = answer === or && va > 5;
  wrongCount = !answerCorrect ? wrongCount + 1 : wrongCount;
  const nextTest = wrongCount === 4 || countMaxCurrentVaIdx >= 2;


  // can move for next
  if (answerCorrect && currentVaIdx >= 0) {
    currentVaIdx += 1;
  }

  // can move for previous
  if (!answerCorrect && currentVaIdx > 0) {
    currentVaIdx -= 1;
  }

  if (!currentVaIdx && currentVaIdx === 0) {
    currentVaIdx = 0;
  }

  if (currentVaIdx >= VA_SIZES.length - 1) {
    currentVaIdx = VA_SIZES.length - 1;
    countMaxCurrentVaIdx += 1;
  }


  va = getNewVA(currentVaIdx);

  if (!nextTest) {
    or = getRandomInt(totalCircles);
  }
  //### based on core.js#handleQ2

  //register answer
  step.answers.push({ value: { answer, answerCorrect, va: originalVA } });

  // update step state
  const changes = { or, va, wrongCount, changeInt, currentVaIdx, countMaxCurrentVaIdx };
  step.state = { ...step.state, ...changes };

  // if nextTest, storage the result
  if (nextTest) {
    step.result = {
      finalizedAt: new Date(),
      value: { [key]: va },
    };
  }

  test.steps[test.workflow] = step;

  await storageTest(test.token, test);

  return nextTest;
};

export const processRectangleAnswer = async (test, answer, key) => {
  const step = test.steps[test.workflow];
  const state = step.state;

  //### based on core.js#handleQ3
  let { or, wrongCount, axis_x, axis_y } = state;

  const answerCorrect = answer !== 5;
  wrongCount = !answerCorrect ? wrongCount + 1 : wrongCount;

  if (answerCorrect) {
    let theta = Q3_ORIENTATIONS[or];
    if (answer === 3) {
      theta = theta + 90;
    }
    theta = theta * (Math.PI / 90); // 90 b/c range from 0-180
    // neg cos is pos x axis, cancels out with final tan
    axis_x = axis_x + Math.cos(theta);
    axis_y = axis_y + Math.sin(theta);
  }

  // move to the next Q3_ORIENTATIONS index
  or = or + 1;

  const nextTest = or === Q3_ORIENTATIONS.length;
  //### END based on core.js#handleQ3

  // update step state
  const changes = { or, wrongCount, axis_x, axis_y };
  step.state = { ...step.state, ...changes };

  //register answer
  step.answers.push({ value: { answer, answerCorrect, state: step.state } });

  // if nextTest, storage the result
  if (nextTest) {
    const angle = getAngle(axis_x, axis_y, wrongCount);
    const wrong_percent =
      (Q3_ORIENTATIONS.length - wrongCount) / Q3_ORIENTATIONS.length;
    step.result = {
      finalizedAt: new Date(),
      value: { [key]: angle, wrong_percent },
    };
  }

  test.steps[test.workflow] = step;

  await storageTest(test.token, test);

  return nextTest;
};

export const calculatePrescription = (test) => {
  const formulas = [formula3, formula4, formula5, formula6, formula7, formula1];

  const result = getCompactTestResult(test);
  const prescription = formulas.reduce((prescription, formula) => {
    return {
      ...prescription,
      ...formula(prescription, result),
    };
  }, {});

  return normalizePrescription(prescription, result);
};

const getCompactTestResult = (test) => {
  let compactAnswer = {
    dob: test.steps.age.result.value.dob,
    age: test.steps.age.result.value.age,
    gender: test.steps.gender.result.value.gender,
    numberOfSteps: test.steps.gender.result.value.steps,
    knowSpecs: test.steps.knowSpecs.result.value.knowSpecs,
    ratio: test.steps.knowSpecs.result.value.ratio,
    haveSpecs: test.steps.haveSpecs?.result?.value?.haveSpecs || "no",
    answer1a: test.steps.nearOrFarObjects.result.value.Answer1a,
    answer1b: test.steps.whichDevice.result.value.Answer1b,
    VAscOD: test.steps.test4CirclesRight.result.value.VAscOD,
    VAscOS: test.steps.test4CirclesLeft.result.value.VAscOS,
    PrimeDrPOVOD: test.steps.testRectRight.result.value.PrimeDrPOVOD,
    PrimeDrPOVOS: test.steps.testRectLeft.result.value.PrimeDrPOVOS,
    VAaOD: test.steps.test2CirclesRight.result.value.VAaOD,
    VAaOS: test.steps.test2CirclesLeft.result.value.VAaOS,

    // default values for possible fields not populated
    VAccOD: null,
    VAccOS: null,
    oldSphOD: null,
    oldSphOS: null,
    oldCylOD: null,
    oldCylOS: null,
    oldAxisOD: null,
    oldAxisOS: null,
    visionQuality: null,
    lensCondition: null,
    lastExam: null,
  };

  if (compactAnswer.knowSpecs === "yes") {
    const { oldSphOD, oldSphOS, oldCylOD, oldCylOS, oldAxisOD, oldAxisOS } =
      test.steps.specs?.result?.value;

    const { visionQuality, lensCondition, lastExam } =
      test.steps.specsInfo.result.value;

    compactAnswer = {
      ...compactAnswer,

      // specs
      oldSphOD,
      oldSphOS,
      oldCylOD,
      oldCylOS,
      oldAxisOD,
      oldAxisOS,

      visionQuality,
      lensCondition,
      lastExam,
    };
  }

  if (compactAnswer.haveSpecs === "yes") {
    compactAnswer = {
      ...compactAnswer,
      // add Glass tests
      VAccOD: test.steps.test4CirclesRightGlassON.result.value.VAccOD,
      VAccOS: test.steps.test4CirclesLeftGlassON.result.value.VAccOS,
    };
  }

  return compactAnswer;
};

const formula3 = (prescription, result) => {
  const { gender, age } = result;
  //ADD POWER
  let Add = FunctionAddPower(gender, age);

  return { Add };
};

const formula4 = (prescription, result) => {
  //Eye VALENCE
  const {
    knowSpecs,
    oldSphOD,
    oldSphOS,
    oldCylOD,
    oldCylOS,
    answer1a,
    answer1b,
  } = result;

  // using default values for contacts info, contact disabled
  const knowContact = "no";
  let CL_OldSphOD = 0;
  let CL_OldCylOD = 0;
  let CL_OldSphOS = 0;
  let CL_OldCylOS = 0;

  const { EyeValenceOD, EyeValenceOS, EyeValenceSafety } = FunctionEyeValence(
    knowSpecs,
    knowContact,
    oldSphOD,
    oldSphOS,
    oldCylOD,
    oldCylOS,
    answer1a,
    answer1b,
    CL_OldSphOD,
    CL_OldSphOS,
    CL_OldCylOD,
    CL_OldCylOS
  );

  return { EyeValenceOD, EyeValenceOS, EyeValenceSafety };
};

const formula5 = (prescription, result) => {
  // SPE
  const { EyeValenceOD, EyeValenceOS } = prescription;
  const { VAscOD, VAscOS, VAaOD, VAaOS, age } = result;

  const SPEscOD = SPEMethods(EyeValenceOD, VAscOD, age);
  const SPEscOS = SPEMethods(EyeValenceOS, VAscOS, age);
  const SPEaOD = SPEMethods(EyeValenceOD, VAaOD, age);
  const SPEaOS = SPEMethods(EyeValenceOS, VAaOS, age);

  return { SPEscOD, SPEscOS, SPEaOD, SPEaOS };
};

const formula6 = (prescription, result) => {
  //MEASURED Rx
  const { EyeValenceOD, EyeValenceOS, SPEscOD, SPEscOS, SPEaOD, SPEaOS } =
    prescription;
  const { PrimeDrPOVOD, PrimeDrPOVOS } = result;

  let measuredRxOD = FunctionMeasuredRx(
    SPEscOD,
    SPEaOD,
    PrimeDrPOVOD,
    EyeValenceOD
  );
  let measuredRxOS = FunctionMeasuredRx(
    SPEscOS,
    SPEaOS,
    PrimeDrPOVOS,
    EyeValenceOS
  );

  const MeasuredSphOD = (Math.round(measuredRxOD.Sphere * 4) / 4).toFixed(2);
  const MeasuredSphOS = (Math.round(measuredRxOS.Sphere * 4) / 4).toFixed(2);
  const MeasuredCylOD = (Math.round(measuredRxOD.Cylinder * 4) / 4).toFixed(2);
  const MeasuredCylOS = (Math.round(measuredRxOS.Cylinder * 4) / 4).toFixed(2);
  const MeasuredAxisOD = measuredRxOD.Axis;
  const MeasuredAxisOS = measuredRxOS.Axis;

  return {
    MeasuredSphOD,
    MeasuredSphOS,
    MeasuredCylOD,
    MeasuredCylOS,
    MeasuredAxisOD,
    MeasuredAxisOS,
  };
};

const formula7 = (prescription, result) => {
  //Weighted FinalRx
  const {
    MeasuredSphOD,
    MeasuredSphOS,
    MeasuredCylOD,
    MeasuredCylOS,
    MeasuredAxisOD,
    MeasuredAxisOS,
  } = prescription;
  const {
    VAccOD,
    VAccOS,
    oldSphOD,
    oldSphOS,
    oldCylOD,
    oldCylOS,
    oldAxisOD,
    oldAxisOS,
  } = result;

  let FinalRxOD = FunctionWeightedRx(
    VAccOD,
    oldSphOD,
    oldCylOD,
    oldAxisOD,
    MeasuredSphOD,
    MeasuredCylOD,
    MeasuredAxisOD
  );
  let FinalRxOS = FunctionWeightedRx(
    VAccOS,
    oldSphOS,
    oldCylOS,
    oldAxisOS,
    MeasuredSphOS,
    MeasuredCylOS,
    MeasuredAxisOS
  );

  const FinalSphOD = FinalRxOD.FinalSph;
  const FinalCylOD = FinalRxOD.FinalCyl;
  const FinalAxisOD = FinalRxOD.FinalAxis;
  const FinalSphOS = FinalRxOS.FinalSph;
  const FinalCylOS = FinalRxOS.FinalCyl;
  const FinalAxisOS = FinalRxOS.FinalAxis;

  return {
    FinalSphOD,
    FinalCylOD,
    FinalAxisOD,
    FinalSphOS,
    FinalCylOS,
    FinalAxisOS,
  };
};

const formula1 = (prescription, result) => {
  // TODO: check why formula1 replace logic of formula7 ??

  let { FinalAxisOD, FinalAxisOS } = prescription;

  if (FinalAxisOD == 0) {
    FinalAxisOD = "180";
  }
  if (FinalAxisOS == 0) {
    FinalAxisOS = "180";
  }

  //rounds the contact lens Axis to the nearest 10 degrees
  const FinalCLAxisOD = Math.round(FinalAxisOD / 10) * 10 || "no axis";
  const FinalCLAxisOS = Math.round(FinalAxisOS / 10) * 10 || "no axis";

  return { FinalAxisOD, FinalAxisOS, FinalCLAxisOD, FinalCLAxisOS };
};

const normalizePrescription = (prescription, result) => {
  return {
    ...getValuesBasedOnResult(result),
    ...getValuesBasedOnFormulas(prescription),
    ...getDefaultContactInfo(),
  };
};

const tryString = (value) => {
  if (value === null || value === undefined) {
    return null;
  }

  return String(value);
};

const getValuesBasedOnFormulas = (prescription) => {
  const {
    Add,
    EyeValenceOD,
    EyeValenceOS,
    EyeValenceSafety,
    SPEscOD,
    SPEscOS,
    SPEaOD,
    SPEaOS,
    MeasuredSphOD,
    MeasuredSphOS,
    MeasuredCylOD,
    MeasuredCylOS,
    MeasuredAxisOD,
    MeasuredAxisOS,
    FinalSphOD,
    FinalCylOD,
    FinalAxisOD,
    FinalCLAxisOD,
    FinalSphOS,
    FinalCylOS,
    FinalAxisOS,
    FinalCLAxisOS,
  } = prescription;

  return {
    // values generated by formulas
    Add: tryString(Add),
    EyeValenceOD: EyeValenceOD,
    EyeValenceOS: EyeValenceOS,
    EyeValenceSafety: EyeValenceSafety,
    SPEscOD: tryString(SPEscOD),
    SPEscOS: tryString(SPEscOS),
    SPEaOD: tryString(SPEaOD),
    SPEaOS: tryString(SPEaOS),
    MeasuredSphOD: MeasuredSphOD,
    MeasuredSphOS: MeasuredSphOS,
    MeasuredCylOD: MeasuredCylOD,
    MeasuredCylOS: MeasuredCylOS,
    MeasuredAxisOD: tryString(MeasuredAxisOD),
    MeasuredAxisOS: tryString(MeasuredAxisOS),
    FinalSphOD: FinalSphOD,
    FinalCylOD: FinalCylOD,
    FinalAxisOD: tryString(FinalAxisOD),
    FinalCLSphOD: FinalSphOD,
    FinalCLCylOD: FinalCylOD,
    FinalCLAxisOD: tryString(FinalCLAxisOD),
    FinalSphOS: FinalSphOS,
    FinalCylOS: FinalCylOS,
    FinalAxisOS: tryString(FinalAxisOS),
    FinalCLSphOS: FinalSphOS,
    FinalCLCylOS: FinalCylOS,
    FinalCLAxisOS: tryString(FinalCLAxisOS),
  };
};

const getValuesBasedOnResult = (result) => {
  const {
    age,
    dob,
    gender,
    knowSpecs,
    haveSpecs,
    ratio,
    oldSphOD,
    oldSphOS,
    oldCylOD,
    oldCylOS,
    oldAxisOD,
    oldAxisOS,
    numberOfSteps,
    visionQuality,
    lensCondition,
    lastExam,
    answer1a,
    answer1b,
    VAccOD,
    VAccOS,
    VAscOD,
    VAscOS,
    PrimeDrPOVOD,
    PrimeDrPOVOS,
    VAaOD,
    VAaOS,
  } = result;

  return {
    // values based on user input
    age: tryString(age),
    dob: dob,
    gender: gender,
    KnowSpec: knowSpecs,
    HaveSpec: haveSpecs,
    ratio: tryString(ratio),
    OldSphOD: oldSphOD,
    OldSphOS: oldSphOS,
    OldCylOD: oldCylOD,
    OldCylOS: oldCylOS,
    OldAxisOD: oldAxisOD,
    OldAxisOS: oldAxisOS,
    NumberOfSteps: tryString(numberOfSteps),
    VisionQuality: visionQuality,
    LensCondition: lensCondition,
    LastExam: lastExam,
    Answer1a: answer1a,
    Answer1b: answer1b,
    VAccOD: tryString(VAccOD),
    VAccOS: tryString(VAccOS),
    VAscOD: tryString(VAscOD),
    VAscOS: tryString(VAscOS),
    PrimeDrPOVOD: tryString(PrimeDrPOVOD),
    PrimeDrPOVOS: tryString(PrimeDrPOVOS),
    VAaOD: tryString(VAaOD),
    VAaOS: tryString(VAaOS),
  };
};

const getDefaultContactInfo = () => {
  return {
    KnowContact: "no",
    CL_OldBrandOD: null,
    CL_OldBrandOS: null,
    CL_OldSphOD: null,
    CL_OldCylOD: null,
    CL_OldAxisOD: null,
    CL_OldBaseCurveOD: null,
    CL_OldDiamOD: null,
    CL_OldSphOS: null,
    CL_OldCylOS: null,
    CL_OldAxisOS: null,
    CL_OldBaseCurveOS: null,
    CL_OldDiamOS: null,
  };
};
